import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  template: `
    <p>
      gallery works!
    </p>
  `,
  styles: [
  ]
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
